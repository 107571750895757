import React from "react"

export default function Footer(props) {
  return (
    <footer role="contentinfo" id="bottom">
      <div className="wrap clearfix">
        <div className="page">
          <span className="current">{props.pageNow}</span><span className="dash">–</span><span className="end">{props.totalPage}</span>
        </div>
        <div className="project"><a title="projects" href=""></a></div>
        <span className="wcover"></span>
      </div>
    </footer>
  )
}